.services-container {
    .service-card {
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      }
  
      .service-icon-wrapper {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .service-icon {
        font-size: 35px;
        color: white;
      }
  
      .service-title {
        font-size: 1.25rem;
        font-weight: 600;
        color: var(--bs-body-color);
      }
  
      .service-description-box {
        background-color: var(--bs-light);
        border-color: var(--bs-border-color) !important;
  
        .service-warning-icon {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
        }
  
        .description-text {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-size: 0.875rem;
          color: var(--bs-gray-700);
        }
      }
  
      .btn-link {
        text-decoration: none;
        font-weight: 500;
        
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  
  // RTL Support
  [dir="rtl"] {
    .service-card {
      .service-icon-wrapper {
        margin-left: 1rem;
        margin-right: 0;
      }
    }
  }
  
  // Responsive Adjustments
  @media (max-width: 768px) {
    .services-container {
      .service-card {
        .service-icon-wrapper {
          width: 50px;
          height: 50px;
        }
  
        .service-title {
          font-size: 1.1rem;
        }
      }
    }
  }
  .modal {
    &.fade {
      .modal-dialog {
        transform: scale(0.95);
        transition: transform 0.3s ease-out;
      }
      
      &.show .modal-dialog {
        transform: scale(1);
      }
    }
  }
  
  #kt_modal_service_details {
    .modal-content {
      border-radius: 1rem;
      background: var(--bs-modal-bg);
      
      .service-details {
        .service-header {
          .service-icon {
            width: 60px;
            height: 60px;
            transition: transform 0.2s ease;
            
            &:hover {
              transform: scale(1.05);
            }
          }
          
          .service-title {
            color: var(--bs-body-color);
          }
        }
        
        .service-description {
          border-color: rgba(var(--bs-primary-rgb), 0.2) !important;
          background-color: rgba(var(--bs-light-rgb), 0.5) !important;
          
          .card-body {
            .description-text {
              color: var(--bs-body-color);
              line-height: 1.6;
            }
          }
        }
        
        .service-actions {
          .btn {
            min-width: 140px;
            transition: all 0.2s ease;
            
            &:hover {
              transform: translateY(-2px);
            }
            
            i {
              font-size: 0.875rem;
            }
          }
        }
      }
    }
  
    // Close button styling
    .btn-close {
      opacity: 0.75;
      transition: opacity 0.2s ease;
      
      &:hover {
        opacity: 1;
      }
    }
  }
  
  // Dark mode support
  [data-bs-theme="dark"] {
    #kt_modal_service_details {
      .modal-content {
        background: var(--bs-modal-bg);
        
        .service-description {
          background-color: rgba(255, 255, 255, 0.05) !important;
        }
      }
    }
  }
  
  // RTL Support
  [dir="rtl"] {
    #kt_modal_service_details {
      .modal-header {
        .btn-close {
          margin-left: 0;
          margin-right: auto;
        }
      }
      
      .service-actions {
        .btn i {
          margin-right: 0;
          margin-left: 0.5rem;
        }
      }
    }
  }
  
  // Responsive adjustments
  @media (max-width: 768px) {
    #kt_modal_service_details {
      .modal-dialog {
        margin: 1rem;
        min-width: auto !important;
      }
      
      .service-actions {
        flex-direction: column;
        gap: 1rem;
        
        .btn {
          width: 100%;
        }
      }
    }
  }
  .service-warning-icon {
    flex-shrink: 0;
    width: 24px !important;
    height: 24px !important;
  }