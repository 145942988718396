.card_custom {
    border-radius: 9px;
    min-width:100%;
    max-width: 100%;
    // height: 310px;
    width: fit-content;
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
    hr{
        margin: 0 10px ;
    }
    cursor: pointer;
}
.card_custom:hover{
    transform: scale(1.05);
 box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.card_hade {
    padding: 10px;
    display: flex;
    justify-content: space-between;
}

.card_icon {
    height: 80px;
    border-radius: 9px;
    overflow: hidden;
    background-color: var(--bs-gray-600);
    // color: black;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.domain {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.card_image {
    width: 50px;
    height: 50px;
}

.card_arrow {
    width: 20px;
    height: 20px;
}
.card_name{
    margin:  10px ;
}
.card_live{
    margin: 20px 10px ;
    display: flex;

}
.live{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 5px;
    grid-row-gap: 0px;
    width: 100%;
}
.live>div{
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    span{
        font-size: 14px;
    }
}
.card_footer{
    display: flex;
    margin: 20px 10px ;
    justify-content: space-between;
    div{
        width: 33.33%;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        img{
            width: 35px;
            height: 35px;
            background-color: #307990;
            border-radius: 9px;
            padding: 5px;
        };
        div{
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap:0
        }
    }
}
.fixed-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 24px; /* Fixed gap between all cards */
    width: 100%;
  }

  .card_custom {
    width: 100% !important;
    margin: 0 !important;
    flex: 0 0 auto;
  }

  /* Center cards and maintain equal spacing */
  @media (min-width: 1200px) {
    .fixed-grid {
      justify-content: flex-start;
    }
  }

  @media (max-width: 1199px) {
    .fixed-grid {
      justify-content: center;
    }
  }

  @media (max-width: 820px) {
    .card_custom {
      width: 100% !important;
      max-width: 370px;
    }
  }

  /* Ensure consistent spacing */
  .card_custom > * {
    width: 100%;
    
  }

  /* Remove any margin/padding that might affect spacing */
  .card_custom > *:not(:last-child) {
    margin-bottom: 1rem;
  }
  .font_size {
    font-size: clamp( 9px, 0.9vw, 13px) ;
  }