.tooltip_tt {
    position: relative;
}

.tooltip_tt .tooltiptext_tt {
    min-width: 50px;
    font-size: 10px;
    visibility: hidden;
    padding: 4px 5px 8px;
    background-color: rgb(86, 85, 114);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    top: -30px;
    right: 6px;
    transform: translateX(50%);
    font-weight: 900;
    clip-path: polygon(0% 0%, 100% 0%, 100% 74%, 61.7% 74%, 49.7% 101%, 38.3% 74%, 0% 74%);
}
.tooltip_tt:hover .tooltiptext_tt {
    visibility: visible;
}

.tooltip_tt .tooltip_text_tt {
    opacity: 1 !important;
    min-width: 80px;
    // font-size: 10px;
    visibility: hidden;
    padding: 4px 5px 8px;
    background-color: rgb(86, 85, 114);
    color: #fff;
    // text-align: center;
    border-radius: 2px;
    position: absolute;
    top: -70px;
    right: 6px;
    transform: translateX(50%);
    font-weight: 900;
    clip-path: polygon(0% 0%, 100% 0%, 100% 74%, 61.7% 74%, 49.7% 101%, 38.3% 74%, 0% 74%);

    span{
    font-size: 10px;
        color:rgb(174, 174, 174)
    }
}


.tooltip_tt:hover .tooltip_text_tt {
    visibility: visible;
}
.google_chart text {
    fill: #fff !important;
}

.certificates {
    width: 100%;
    height: fit-content;

    div {

        max-width: fit-content;
        text-align: center;
    }

    .certificates_details {
        padding: 10px 10px 0 10px;
        transform: scaleY(0);
        transform-origin: top;
        height: 0;
    }
}

.certificates[show='true'] {
    div {
        max-width: 100% !important;
    }

    .certificates_details {
        height: fit-content;
        transform: scaleY(1);

        p {
            text-align: start !important;
        }
    }
}
.date_box:hover{
    border-color: rgb(190, 189, 189) !important;
}
.timeline_hover:hover{
    opacity: 0.5;
}
.certificates_show {
    div {
        max-width: 100% !important;
    }

    .certificates_details {
        height: fit-content;
        transform: scaleY(1);

        p {
            text-align: start !important;
        }
    }
}
.date_box:hover{
    border-color: rgb(190, 189, 189) !important;
}
.timeline_hover:hover{
    opacity: 0.5;
}
