._failed {
    border-bottom: solid 4px red !important;
}

._failed i {
    color: red !important;
}

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 100%;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #28a745;
}

._success i {
    font-size: 55px;
    color: #28a745;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}

@media print {
    body * {
        visibility: hidden !important;
    }

    #kt_modal_invoice,
    #kt_modal_invoice * {
        visibility: visible !important;
    }

    #kt_modal_invoice {
        .modal-dialog-centered {
            min-width: 100vw !important;
            max-width: 100vw !important;
            width: 100vw !important;
            border: none !important;
            position: absolute !important;
            left: 0 !important;
            top: 0 !important;

            .btn,
            .svg-icon {
                visibility: hidden !important;
            };

            svg {
                display: none !important;
            };

            .bi {
                display: none !important;
            };
        }

        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        border: none !important;
    }
}