.icon-box{
    color: #2FAC66;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    background: #f2f2f2;
    display: inline-block;
    line-height: 62px;
    font-size: 28px;
}
.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.glyphicon-ok:before {
    content: "\e013";
}