.loader_nuclei-loader__1w7x9 {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(#5c5b5b,#5b5b5c,#424242,#2a2a2a,#2a2a2a,#181818,#0f0f10,#000);
  animation: loader_animate__rjMCF 10.9s linear infinite;
  z-index: 5
}

@keyframes loader_animate__rjMCF {
  0% {
      transform: rotate(0grad)
  }

  to {
      transform: rotate(380grad)
  }
}

.loader_nuclei-loader__1w7x9 span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#b8b7b7,#909091,#7d7d7d,#5e5e5e,#3f3f3f,#222,#0f0f0f,#070707);
  animation: loader_animate__rjMCF .5s linear infinite;
  z-index: 5
}

.loader_nuclei-loader__1w7x9 span:first-child {
  filter: blur(5px);
  z-index: 5
}
.loader_nuclei-loader__1w7x9 {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(#5c5b5b,#5b5b5c,#424242,#2a2a2a,#2a2a2a,#181818,#0f0f10,#000);
  animation: loader_animate__rjMCF 10.9s linear infinite;
  z-index: 5
}

@keyframes loader_animate__rjMCF {
  0% {
      transform: rotate(0grad)
  }

  to {
      transform: rotate(380grad)
  }
}

.loader_nuclei-loader__1w7x9 span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#b8b7b7,#909091,#7d7d7d,#5e5e5e,#3f3f3f,#222,#0f0f0f,#070707);
  animation: loader_animate__rjMCF .5s linear infinite;
  z-index: 5
}

.loader_nuclei-loader__1w7x9 span:first-child {
  filter: blur(5px);
  z-index: 5
}

.loader_nuclei-loader__1w7x9 span:nth-child(2) {
  filter: blur(10px);
  z-index: 5
}

.loader_nuclei-loader__1w7x9 span:nth-child(3) {
  filter: blur(25px);
  z-index: 5
}

.loader_nuclei-loader__1w7x9 span:nth-child(4) {
  filter: blur(50px);
  z-index: 5
}

.loader_nuclei-loader__1w7x9:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #2a2a2b;
  border-radius: 50%;
  z-index: 5
}