
// smtp.scss
.smtp-header {
    .card-body {
      padding: 1rem;
    }
    
    button {
      min-width: 40px;
      height: 40px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:disabled {
        opacity: 0.5;
      }
    }
  }
  
  .section-card {
    .card-header {
      background-color: rgba(0, 0, 0, 0.03);
      transition: background-color 0.2s;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    
    .section-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      padding: 0;
      
      &.show {
        max-height: 2000px;
        padding: 1rem;
      }
    }
  }
  
  .subsection {
    .cursor-pointer {
      cursor: pointer;
    }
    
    .subsection-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      
      &.show {
        max-height: 1000px;
      }
    }
  }
  
  .cursor-pointer {
    cursor: pointer;
  }