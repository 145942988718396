/* button */
.btn_animation {
    padding: 10px 15px;
    border: none;
    outline: none;
    cursor: pointer;
    position: absolute;
    z-index: 0;
    border-radius: 12px;
    width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 98;
}

.btn_animation::after {
    content: "";
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000000;
    left: 0;
    top: 0;
    border-radius: 10px;
    transition: opacity .3s ease-in-out;
}

.btn_animation::before {
    content: "";
    background: linear-gradient(45deg,
            #FF0000, #FF7300, #FFFB00, #48FF00,
            #00FFD5, #002BFF, #FF00C8, #FF0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 600%;
    z-index: -1;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    filter: blur(3px) grayscale(1);
    animation: glowing 20s linear infinite;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    opacity: 1;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.btn_animation:hover::after {
    opacity: 0.9;
}
.btn_animation:hover::before {
    filter: grayscale(0) blur(6px)
}

.btn_animation:active:after {
    background: transparent;
}

.btn_animation:active {
    color: #000;
    font-weight: bold;
}

:root[data-bs-theme=light] {

    .btn_animation::after {
        content: "";
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        left: 0;
        top: 0;
        border-radius: 10px;
        transition: opacity .3s ease-in-out;
    }




}