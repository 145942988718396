//
// Component: Small Box
//

.app_d{
    .small-box {
    // @include border-radius($border-radius);
    // @include box-shadow($card-shadow);

    display: block;
    margin-bottom: 20px;
    position: relative;

    // content wrapper
    >.inner {
        padding: 10px;
    }

    >.small-box-footer {
        background: rgba(0, 0, 0, .1);
        color: rgba(255, 255, 255, .8);
        display: block;
        padding: 3px 0;
        position: relative;
        text-align: center;
        text-decoration: none;
        z-index: 10;

        &:hover {
            background: rgba(0, 0, 0, 0.15);
            color: #fff;
        }
    }

    h3 {
        font-size: 2.2rem;
        font-weight: bold;
        margin: 0 0 10px 0;
        padding: 0;
        white-space: nowrap;
    }


    p {
        font-size: 1rem;

        >small {
            color: var(--bs-gray-100);
            display: block;
            font-size: 0.9rem;
            margin-top: 5px;
        }
    }

    h3,
    p {
        z-index: 5;
        color: #fff;
    }

    // the icon
    .icon {
        color: rgba(0, 0, 0, 0.15);
        z-index: 0;

        >i {
            font-size: 90px;
            position: absolute;
            right: 15px;
            top: 15px;
            transition: all 0.3s linear;

            &.fa,
            &.fas,
            &.far,
            &.fab,
            &.glyphicon,
            &.ion {
                font-size: 70px;
                top: 20px;
            }
        }

    }

    // Small box hover state
    &:hover {
        text-decoration: none;

        // Animate icons on small box hover
        .icon>i {
            font-size: 95px;

            &.fa,
            &.fas,
            &.far,
            &.fab,
            &.glyphicon,
            &.ion {
                font-size: 75px;
            }
        }
    }
}

//
// Base styles
//

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;

    >hr {
        margin-right: 0;
        margin-left: 0;
    }

}

.card-body {
    flex: 1 1 auto;
    // color: #fff;
}

.card-text:last-child {
    margin-bottom: 0;
}


.card-header {
    margin-bottom: 0; // Removes the default margin-bottom of <hN>

}




//
// Header navs
//



// Card image
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.card-img {
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

// Card image caps
.card-img-top {
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img-bottom {
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}


// Card deck

.card-deck {
    display: flex;
    flex-direction: column;



}



.card-group {
    display: flex;
    flex-direction: column;
}

.accordion {
    >.card {
        overflow: hidden;
    }
}

.test {
    pre {
        display: block;
        font-size: 87.5%;
        color: var(--bs-gray-900) !important;

        // height: 400px;
        // Account for some code outputs that place code tags in pre tags
        code {
            font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
            height: 400px;
            color: var(--bs-gray-900) !important;
            word-break: normal !important;
            text-wrap: auto;
            font-size: 16px !important;
            background-color: unset !important;
            border: unset !important;
            box-shadow: unset !important;
        }

        // Enable scrollable blocks of code
        .pre-scrollable {

            overflow-y: scroll;
        }
    }
}

.fa,
.fas {
    font-weight: 900;
}

.fa,
.far,
.fas {
    font-family: "Font Awesome 5 Free";
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

#app_icon {
    width: 64px;
    height: 64px;
}

div.dataTables_wrapper {
    position: relative;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    background-color: transparent;
}

table.dataTable {
    clear: both;
    margin-top: 6px !important;
    margin-bottom: 6px !important;
    max-width: none !important;
    border-collapse: separate !important;
    border-spacing: 0;
}

.table:not(.table-dark) {
    color: inherit;
}}