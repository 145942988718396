@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 10;
    src: url("./fonts/poppins/Poppins-Thin.ttf")
}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 200;
    src: url("./fonts/poppins/Poppins-ExtraLight.ttf");

}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/poppins/Poppins-Light.ttf");

}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/poppins/Poppins-Medium.ttf");

}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 600;
    src: url("./fonts/poppins/Poppins-SemiBold.ttf");

}

// @font-face {
//     font-family: poppins;
//     font-style: normal;
//     font-weight: 750;
//     src: url("./fonts/");

// }

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 800;
    src: url("./fonts/poppins/Poppins-ExtraBold.ttf");

}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 900;
    src: url("./fonts/poppins/Poppins-Black.ttf");

}

@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/poppins/Poppins-Bold.ttf");;

}

@font-face {
    font-family: poppins !important;
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/poppins/Poppins-Regular.ttf");

}





:root[dir=ltr] {


    html,
    body {

        font-family: "Poppins" !important;
    }


}




.border-iinfo,
.border-iINFO {
    border-color: green !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.border-ilow,
.border-iLOW {
    border-color: yellowgreen !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.border-imedium,
.border-iMEDIUM {
    border-color: orange !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.border-ihigh,
.border-iHIGH {
    border-color: rgb(255, 0, 51) !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.border-icritical,
.border-iCRITICAL {
    border-color: #990024 !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
}

.bg-iinfo {
    background-color: green !important;
}

.bg-ilow {
    background-color: yellowgreen !important;
}

.bg-imedium {
    background-color: orange !important;
}

.bg-ihigh {
    background-color: crimson !important;
}

.bg-icritical {
    background-color: #f8285a !important;
}

.no_reduce {
    border-radius: 0px !important;
}

/////////////////////////
/* Skeleton background */
.skeleton {
    position: relative;
    overflow: hidden;

    filter: blur(3px);

}

/* Light animation */
.skeleton::before {
    content: '';
    position: absolute;
    z-index: 10;
    top: -100%;
    right: -100%;
    width: 200%;
    height: 200%;
    background: linear-gradient(0deg,
            rgba(255, 255, 255, 0) 45%,
            rgba(255, 255, 255, 0.3) 50%,
            /* Adjust width of the light */
            rgba(255, 255, 255, 0) 55%);
    // transform: rotate(45deg);
    animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
    0% {
        transform: translate(0%, 0%) rotate(45deg);
    }

    100% {
        transform: translate(-100%, 100%) rotate(45deg);
    }
}

/////////////////////////
.chat_loading {
    width: 40px;
    aspect-ratio: 1;
    color: #f03355;
    position: relative;
    background: radial-gradient(10px, currentColor 94%, #0000);
}

.chat_loading:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background:
        radial-gradient(9px at bottom right, #0000 94%, currentColor) top left,
        radial-gradient(9px at bottom left, #0000 94%, currentColor) top right,
        radial-gradient(9px at top right, #0000 94%, currentColor) bottom left,
        radial-gradient(9px at top left, #0000 94%, currentColor) bottom right;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    animation: l18 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes l18 {
    33% {
        inset: -10px;
        transform: rotate(0deg)
    }

    66% {
        inset: -10px;
        transform: rotate(90deg)
    }

    100% {
        inset: 0;
        transform: rotate(90deg)
    }
}

/////////////////////////
.gradient {
    background: rgb(24, 24, 27);
    background: -moz-linear-gradient(101deg, rgba(24, 24, 27, 1) 0%, rgba(51, 51, 64, 1) 44%);
    background: -webkit-linear-gradient(101deg, rgba(24, 24, 27, 1) 0%, rgba(51, 51, 64, 1) 44%);
    background: linear-gradient(101deg, rgba(24, 24, 27, 1) 0%, rgba(51, 51, 64, 1) 44%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#18181b", endColorstr="#333340", GradientType=1);
}

.rotate_180 {
    rotate: 180deg;
}

.dropdown_arrow_none::after {
    display: none !important;
}

.Active,
.Active:hover {
    background-color: var(--bs-primary);
    color: white;
    margin: 0 4px;
    width: calc(100% - 8px);
    border-radius: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.text_hide_3line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Limits the content to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text_hide_width {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* Limits the content to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.hover_Costume:hover {
    border: 1px solid var(--bs-gray-500);
    background-color: var(--bs-gray-300);
    cursor: pointer;
}

.selected_Costume {
    border: 1px solid var(--bs-gray-500);
    background-color: var(--bs-gray-300);
    cursor: pointer;
}

.hover_size_costume {
    transition: all 300ms ease-in-out;
    cursor: pointer;
}

.hover_size_costume:hover {
    transition: all 300ms ease-in-out;
    width: 101% !important;
    height: 165px !important;
    cursor: pointer;
}


.item_hover:hover {
    background-color: var(--bs-gray-100);
    cursor: pointer;
}

.custom_text_decoration {
    border-bottom: 1px solid #a65c5c;
    background-color: transparent;
    transition: all 0.3s ease;
    cursor: pointer;
}

.custom_text_decoration:hover {
    background-color: #973636;
    transition: all 0.3s ease;
}

.monaco-editor .margin-view-overlays .current-line {
    width: 100% !important;
}

.margin-view-overlays {
    max-width: 100% !important;
}

.margin {
    width: 50px !important;
    background-color: #99999947 !important;
}

.monaco-editor .line-numbers.active-line-number {
    width: 100% !important;
    text-align: center !important;
}

.monaco-editor-background {
    background-color: transparent !important;
}

.monaco-editor,
.monaco-editor-background {
    background-color: transparent !important;
}
.form_code_editor{
    .margin{
        width: 26px !important;
        background-color:  #9999990d !important;
    }
    .line-numbers{
        max-width: 100%;
    }
}
.form_code_editor_full{
    .margin{
        width: 68px !important;
        background-color:  #9999990d !important;
    }
    .line-numbers{
        max-width: 29px;
    }
}
.svg_config_code_editor{
    .svg-icon{
        right: 0 !important;
        top: -27px !important;
        cursor: pointer !important;
        svg{
            width: 25px !important;
            height: 25px !important;
        }
    }

}
.scoreLabel {

    transform: rotate(45deg);
    right: -41px;
    top: -28px;
    font-size: 24px;
    padding: 0px 42px;
    font-weight: bolder;

    span {
        transform: rotate(-45deg);
    }
}

:root[data-bs-theme=light] {
    .image_change_theme {
        filter: invert(1);
    }

    .svg-icon-danger {
        svg {

            path {
                fill: #f8285a
            }
        }
    }

    .svg-icon-warning {
        svg {

            path {
                fill: #f6c000
            }
        }
    }

    .svg-icon-success {
        svg {

            path {
                fill: #17c653
            }
        }
    }

    .svg-icon-primary {
        svg {

            path {
                fill: #006ae6
            }
        }
    }

    .svg-icon-info {
        svg {

            path {
                fill: #883fff
            }
        }
    }

    .btn-group {
        .btn {
            color: #000;
        }
    }
}

.sonner button:first-child {


    border-radius: 50% !important;

}


:root[data-bs-theme=dark] {
    .image_change_theme2 {
        filter: invert(1);
    }

    .svg-icon-danger {
        svg {

            path {
                fill: #f8285a
            }
        }
    }

    .svg-icon-warning {
        svg {

            path {
                fill: #f6c000
            }
        }
    }

    .svg-icon-success {
        svg {

            path {
                fill: #17c653
            }
        }
    }

    .svg-icon-primary {
        svg {

            path {
                fill: #006ae6
            }
        }
    }

    .svg-icon-info {
        svg {

            path {
                fill: #883fff
            }
        }
    }

    .btn-group {
        .btn {
            color: #fff;
        }
    }

    .react-flow__controls-button {
        background: #242731;
    }

}

.abc {

    opacity: 1;
    // animation: fadePlaceholder 3s infinite;
    animation: 3s linear 1s infinite alternate fadePlaceholder;
}

@keyframes fadePlaceholder {
    from {
        /* pushes the sun down past the viewport */
        opacity: 1;
    }

    to {
        /* returns the sun to its default position */
        opacity: 0;
    }


}

.option {

    font-family: 'Font Awesome 5 Free', Arial, sans-serif;

}


.ai_btn {
    position: fixed;
    display: none;
    cursor: pointer;
    z-index: 105;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    bottom: 65px;
    right: 7px;
    box-shadow: var(--bs-scrolltop-box-shadow);
    opacity: 0;
    transition: color 0.2s ease;
    border-radius: 0.475rem;

    .svg-icon {
        // @include svg-icon-size(24px);
        color: var(--bs-scrolltop-icon-color);
    }

    >i {
        font-size: 44px;
        color: var(--bs-scrolltop-icon-color);
    }

    &:hover {

        // background-color: var(--bs-info-active);
        .svg-icon,
        i {
            color: var(--bs-scrolltop-icon-color-hover);
        }
    }


    opacity: var(--bs-scrolltop-opacity-on);
    animation: animation-scrolltop .4s ease-out 1;
    display: flex;

    &:hover {
        // transition: $transition-link;
        opacity: var(--bs-scrolltop-opacity-hover);
    }



}

// Animations
@keyframes animation-scrolltop {
    from {
        margin-bottom: -15px;
    }

    to {
        margin-bottom: 0;
    }
}

.scrolltop {
    bottom: 20px !important;
}

.cursor-pointer {
    cursor: pointer;
}

.list-group{
    --bs-list-group-bg : --bs-gray-300;
    // background-color:  !important;
}