.tooltip_tt {
    position: relative;
}

.tooltip_tt .tooltiptext_tt {
    min-width: 50px;
    font-size: 10px;
    visibility: hidden;
    padding:4px 5px 8px;
    background-color: rgb(86, 85, 114);
    color: #fff;
    text-align: center;
    border-radius: 2px;
    position: absolute;
    top: -30px;
    right: 6px;
    transform: translateX(50%);
    font-weight: 900;
    clip-path: polygon(0% 0%, 100% 0%, 100% 74%, 61.7% 74%, 49.7% 101%, 38.3% 74%, 0% 74%);
}

.tooltip_tt:hover .tooltiptext_tt {
    visibility: visible;
}
.rmdp-container {
    width: 100% !important;
}
.bg-dark.rmdp-wrapper,
.bg-dark .rmdp-month-picker,
.bg-dark .rmdp-year-picker,
.bg-dark .rmdp-time-picker div input,
.rmdp-container .bg-dark.ep-arrow::after {
  background-color: #312f2f !important;
  color: #c4c7ca !important;
}

.bg-dark .rmdp-day:not(.rmdp-deactive),
.bg-dark .rmdp-time-picker div .rmdp-am,
.bg-dark .rmdp-header-values,
.bg-dark .rmdp-panel-header {
  color: #c4c7ca !important;
}

.bg-dark .rmdp-day.rmdp-range {
  color: white !important;
}

.bg-dark .rmdp-panel-body li {
  color: #f5f5f5 !important;
}

.bg-dark .rmdp-day.rmdp-deactive,
.bg-dark .rmdp-day.rmdp-disabled {
  color: #87898b !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="top"] {
  border-bottom: 1px solid #312f2f !important;
  background-color: transparent !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="left"] {
  border-right: 1px solid #312f2f !important;
  background-color: transparent !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="right"] {
  border-left: 1px solid #312f2f !important;
  background-color: transparent !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="bottom"] {
  border-top: 1px solid #312f2f !important;
  background-color: transparent !important;
}

.hover:hover{
   cursor: pointer;
   border: 1px solid rgb(201, 201, 201)
}


.front_card, .front_card_y , .back_card, .back_card_y {
  position: absolute; 
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.back_card{
  transform: rotateX(180deg);
}
.front_card_y{
  transform: rotateY(-180deg);
}
.back_card_y{
  transform: rotateY(180deg);
}
.inner_box{
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}